#payement-footer{
    text-align: center;
    img{
        @media(max-width:767px){
            max-width: 80px;
        }
    }
}

#newsletter_block_left{
    @media(max-width:767px){
        clear: both;
        padding-top: 15px;
    }
    #newsletter-input{
        margin-bottom: 10px;
    }
}